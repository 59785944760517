var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-drawer',{attrs:{"title":"客户分组","visible":_vm.visible,"direction":"rtl","size":"70%"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticStyle:{"padding":"0 10px"}},[_c('el-transfer',{staticStyle:{"width":"100%"},attrs:{"filterable":"","filter-method":_vm.filterMethod,"filter-placeholder":"请输入客户名称","data":_vm.data,"titles":_vm.titles,"props":{
        key: 'Id',
        label: 'cCusName',
      }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('div',{staticStyle:{"height":"60px"}}),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '5px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 99999,
    })},[_c('el-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":_vm.handleSubmit}},[_vm._v("保存")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }